<template>
    <v-card outlined flat class="text-center">
        <v-card-text class="fill-height align-center justify-center">
            <div class="d-inline-block my-auto">
                <v-icon class="d-block" size="100">{{ icon }}</v-icon>
                <span class="title font-weight-regular py-5">{{ text }}</span>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ['icon', 'text']
}
</script>