<template>
    <div id="wrapper">
        <div id="chart-line2" class="overflow-hidden">
            <VueApexCharts type="line" :height="height" :options="options" :series="series" />
        </div>
        <div id="chart-line">
            <VueApexCharts type="area" :height="150" :options="line_options" :series="series" />
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

export default {
    props: {
        series: {
            type: Array,
            default: () => ([])
        },
        height: {
            type: Number,
            default: 230
        }
    },
    components: {
        VueApexCharts
    },
    data: () => ({
        options: {
            chart: {
                id: 'chart2',
                type: 'line',
                height: 230,
                animations: { enabled: false },
                toolbar: {
                    autoSelected: 'pan',
                    show: false
                }
            },
            stroke: {
                width: 2
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                opacity: 1,
            },
            markers: {
                size: 0
            },
            xaxis: {
                type: 'datetime',
            },
            tooltip: {
                x: {
                    formatter: function (val) {
                        return moment(val).format("L HH:mm")
                    }
                }
            },
        },
        line_options: {
            legend: {
                show: false
            },
            chart: {
                id: 'chart1',
                height: 150,
                type: 'area',
                animations: { enabled: false },
                brush:{
                    target: 'chart2',
                    enabled: true
                },
                selection: {
                    enabled: true,
                    xaxis: {
                        min: moment().subtract(1, 'day').toDate().getTime(),
                        max: moment().toDate().getTime()
                    }
                },
            },
            stroke: {
                width: 2
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 1,
                    opacityTo: 0.8,
                }
            },
            xaxis: {
                type: 'datetime',
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                tickAmount: 2
            }
        }
    })
}
</script>